<template>
    <div class="main">

        <div class="actionBar">
            <a-space>
                <a-tree-select allowClear v-model="listParams.cat_id" style="width: 140px" :tree-data="classify"
                    placeholder="分类" :replaceFields="{ value: 'id' }" />

                <a-tree-select allowClear v-model="listParams.status" style="width: 100px" :tree-data="check_status"
                    placeholder="状态" />

                <a-range-picker style="width: 280px" v-model="time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                    :locale="locale" @change="timeChange">
                </a-range-picker>

                <a-input-search v-model="listParams.nickname" placeholder="用户名称" enter-button="查询" style="width: 250px"
                    allowClear @search="onSearch" />
            </a-space>
        </div>

        <a-table :columns="columns" :data-source="data" rowKey="id" :pagination="{
            total: count,
            current: listParams.page,
            pageSize: listParams.limit,
            showTotal: (total) => `共 ${total} 条`,
        }" @change="pageChange">
            <span slot="index" slot-scope="text, record, index">
                {{ index }}
            </span>

            <span slot="check_status" slot-scope="text">
                <a-tag color="#999" v-if="text == 1">
                    待审核
                </a-tag>
                <a-tag color="blue" v-else-if="text == 2">
                    已确认
                </a-tag>
                <a-tag color="red" v-else-if="text == 3">
                    已驳回
                </a-tag>
                <a-tag color="green" v-else-if="text == 4">
                    已打款
                </a-tag>
            </span>

            <span slot="action" slot-scope="text, record">
                <a-space>
                    <a-button :type="record.check_status == 1 ? 'primary' : ''" size="small" @click="goDetail(record.id)">{{ record.check_status == 1 ? '审核' : '详情' }}</a-button>
                    <a-popconfirm title="确定删除？" ok-text="确定" cancel-text="取消" @confirm="confirm(record)" v-if="record.check_status == 3">
                        <a-button type="danger" size="small">删除</a-button>
                    </a-popconfirm>
                </a-space>
            </span>
        </a-table>


    </div>
</template>

<script>
import {
    materialIndex,
    materialDelete,
    materialCatShowList
} from "@/apis/index";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
const columns = [
    {
        title: "ID",
        dataIndex: "id",
        scopedSlots: { customRender: "id" },
        align: "center",
    },
    {
        title: "资料名称",
        dataIndex: "title",
        scopedSlots: { customRender: "title" },
    },
    {
        title: "上传人",
        dataIndex: "user_name",
        scopedSlots: { customRender: "user_name" },
    },
    {
        title: "栏目",
        dataIndex: "group_name",
        scopedSlots: { customRender: "group_name" },
    },
    {
        title: "视频数量",
        dataIndex: "videos",
        scopedSlots: { customRender: "videos" },
        customRender: (text) => {
            return text.count

        },
    },
    {
        title: "文件数量",
        dataIndex: "files",
        scopedSlots: { customRender: "files" },
        customRender: (text) => {
            return text.count
        },
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
        scopedSlots: { customRender: "create_time" },
        customRender: (text) => {
            // return text.count
            return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");

        },
    },
    {
        title: "状态",
        dataIndex: "check_status",
        scopedSlots: { customRender: "check_status" },
    },
    {
        title: "分值",
        dataIndex: "score",
        scopedSlots: { customRender: "score" },
    },
    {
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" },
        width: 200,
    },
];

const check_status = [
    {
        title: "全部",
        value: 0,
    },
    {
        title: "待审核",
        value: 1,
    },
    {
        title: "已确认",
        value: 2,
    },
    {
        title: "已驳回",
        value: 3,
    },
    {
        title: "已打款",
        value: 4,
    },
];

export default {
    data() {
        return {
            columns,
            data: [],
            check_status,
            locale,
            time: [],
            listParams: {
                nickname: '',
                status: undefined,
                cat_id: undefined,
                start_time: '',
                end_time: '',
                page: 1,
                limit: 10,
            },
            count: 0,
            classify: []
        }
    },
    created() {
        if(this.$route.query.status){
            this.listParams.status=1
        }

        materialCatShowList().then(res => {
            this.classify = res.data.list
        })
        // this.getmaterialIndex()
    },
    activated(){
        this.getmaterialIndex()
    },
    methods: {
        goDetail(id){
            this.$router.push({ path: "/DataAdministration/DataAdministrationDetail", query: { id } });
        },
        confirm(rec) {
            materialDelete({ id: rec.id }).then(res => {
                if(res.code==1){
                    this.$message.success("删除成功");
                    this.getmaterialIndex()
                }
            })
        },
        onSearch() {
            this.listParams.page=1
            this.getmaterialIndex()

        },
        timeChange(e) {
            this.listParams.start_time = e[0]
            this.listParams.end_time = e[1]
        },
        getmaterialIndex() {
            materialIndex(this.listParams).then(res => {
                this.data = res.data.list
                this.count = res.data.count
            })
        },
        pageChange(a) {
            this.listParams.page = a.current;
            this.getmaterialIndex()
        }
    }
}
</script>

<style lang="less" scoped>
.main {
    .actionBar {
        padding: 30px 0;
    }
}
</style>